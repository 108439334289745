import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../layouts/layout"
import TextColumns from "../components/TextColumns"
import CTA from "../components/CTA"
import ScrollMagic from "scrollmagic"
import { appearAnimation } from "../animations"

class SecuritySolutions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      pageContext: this.props.pageContext,
    }
  }

  componentDidMount() {
    const controller = new ScrollMagic.Controller()

    const appearElements = [
      document.querySelector(".content__intro"),
      document.querySelector(".content__footnote"),
    ]

    appearElements.forEach(el => {
      new ScrollMagic.Scene({
        triggerElement: el,
        offset: -500,
      })
        .on("enter", () => {
          appearAnimation(el)
        })
        .addTo(controller)
    })
  }

  render() {
    console.log(this.state)

    const security = this.state.data.wordpressPage.acf.content_security

    const enoughContent =
      security.text || security.text_column_list.length > 1 ? true : false

    return (
      <Layout
        lang={this.state.pageContext.lang}
        tpl={this.state.pageContext.templateGT}
        intro="parallaxed"
        introModifier="intro--security"
        pageContext={this.state.pageContext}
      >
        {enoughContent && (
          <section id="content" className="content--security">
            <div className="grid">
              <div className="row">
                <div className="col lg-10-12 lg-push-left-1-12">
                  <div className="content__container js-scroll-target">
                    {security.text && (
                      <div className="content__intro appear ">
                        <h2
                          dangerouslySetInnerHTML={{ __html: security.text }}
                        />
                      </div>
                    )}
                    <TextColumns
                      columns={security.text_column_list}
                      modifier="text-columns--security"
                    />
                    {security.footnote && (
                      <p className="content__footnote appear">
                        {security.footnote}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <CTA
          modifier="cta--white"
          pageID={this.state.pageContext.id}
          lang={this.state.pageContext.lang}
        />
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query($id: Int) {
        wordpressPage(wordpress_id: { eq: $id }) {
          acf {
            content_security {
              text
              footnote
              text_column_list {
                title
                text
              }
              footnote
            }
          }
        }
      }
    `}
    render={(data, pageContext) => (
      <SecuritySolutions data={data} context={pageContext} {...props} />
    )}
  />
)
